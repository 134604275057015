@import '~@gravity-angular/styles/lib/xg-styles.css';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
.iperl_germany_flag_icon {
    background: url('assets/748067_flag_germany.png');
    height: 25px;
    width: 30px;
}

.iperl_uk_flag_icon {
    background: url('assets/748024_united_kingdom_flag.png');
    height: 25px;
    width: 30px;
}

.container .multiselect-dropdown .dropdown-btn {
    width: 200px!important;  }

.error-snackbar {
    background: #FFFFFF !important;
    color: #545c68;
    height: 30px;
    font-size: 15px;

    .mat-button-wrapper {
        padding: 9px 15px 9px 15px;
        background: #0085ad;
        color: #FFFFFF;
        width: 20px;
        border-radius: 6px;
    }
    .mat-button-wrapper:hover {
        background: #009fcf;
    }
    .mat-button {
        padding: 0;
        line-height: 0;
    }
}